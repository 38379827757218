'use client'

import Script from 'next/script'
// TODO: Evaluate import 'client only'
import React from 'react'

declare global {
  interface Window {
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    dataLayer?: Object[]
  }
}

let currDataLayerName: string | undefined = undefined

export function GoogleAnalytics(props: {
  gaId: string
  dataLayerName?: string
}) {
  const { gaId, dataLayerName = 'dataLayer' } = props

  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName
  }

  React.useEffect(() => {
    // performance.mark is being used as a feature use signal. While it is traditionally used for performance
    // benchmarking it is low overhead and thus considered safe to use in production and it is a widely available
    // existing API.
    // The performance measurement will be handled by Chrome Aurora

    performance.mark('mark_feature_usage', {
      detail: {
        feature: 'next-third-parties-ga',
      },
    })
  }, [])

  return (
    <>
      <Script
        id='_next-ga-init'
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={{
          __html: `
          window['${dataLayerName}'] = window['${dataLayerName}'] || [];
          function gtag(){window['${dataLayerName}'].push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaId}');`,
        }}
      />
      {/*<Script id='inapp-js' src='http://localhost:3005/js/foo.js' />*/}
      <Script id='inapp-js' src='https://typeit.ai/js/foo.js' />
    </>
  )
}

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export function sendGAEvent(..._args: Object[]) {
  if (currDataLayerName === undefined) {
    console.warn('@next/third-parties: GA has not been initialized')
    return
  }

  // @ts-ignore
  if (window[currDataLayerName]) {
    // @ts-ignore
    // biome-ignore lint/style/noArguments: <explanation>
    window[currDataLayerName].push(arguments)
  } else {
    console.warn(`@next/third-parties: GA dataLayer ${currDataLayerName} does not exist`)
  }
}

export function ConversionEvent() {
  React.useEffect(() => {
    sendGAEvent({
      event: 'conversion_event_page_view',
    })
  }, [])

  return <></>
}
