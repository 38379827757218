import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/vercel/path0/components/layout/ga.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/vercel/path0/components/layout/progress-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./pretendard-variable.woff2\",\"display\":\"swap\",\"variable\":\"--font-pretendard\",\"weight\":\"45 920\"}],\"variableName\":\"pretendard\"}");
